.footer {
	padding: 55px 4.2% 40px;
	background: $color-content;
	color: #fff;
	overflow: hidden;
	.trading-networks {
		display: flex;
		justify-content: center;
		padding-bottom: 20px;
	}
	.trade-logo {
		margin: 0 30px 40px;
	}
	.contact {
		display: flex;
		justify-content: center;
	}
	.social-block {
		font-size: 16px;
		color: inherit;
		text-decoration: none;
		margin-right: 20px;
		margin-left: 90px;
		display: flex;
		align-items: flex-start;
		.fb-text {
			line-height: 60px;
			margin-right: 16px;
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background: #fff;
				position: absolute;
				right: 0;
				bottom: 20px;
				opacity: 0;
				transition: opacity .15s;
			}
		}
		.fb-icon {
			width: 60px;
			height: 60px;
			transform: rotate(45deg);
			position: relative;
			&:before {
				content: '';
				display: block;
				width: 40px;
				height: 40px;
				border: 2px solid transparent;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				transition: border-color .15s;
			}
		}
		svg {
			width: 60px;
			transform: rotate(-45deg);
			.st0, .st2 {transition: all .15s;}
		}
		&:hover {
			.fb-text:before {opacity: 1;}
			.st0 {fill:none;}
			.st2{fill:#FFFFFF;}
			.fb-icon:before {border-color: #fff;}
		}
	}
	.phone-block {
		position: relative;
		padding-left: 130px;
	}
	.phone-text {
	}
	.hotline {
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.tel {
		font-size: 30px;
		font-weight: bold;
		color: inherit;
		text-decoration: none;
	}
	.hotline-info {
		font-size: 12px;
	}
	.phone-img {
		width: 85px;
		height: 250px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		transform: rotate(45deg) translate(-70%,4%);
		&:before {
			content: '';
			display: block;
			width: 30px;
			height: 100%;
			background: rgba(0,0,0,.3);
			position: absolute;
			top: 0;
			left: 90%;
			z-index: -1;
			transform: rotate(-10deg);
		}
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: #fff;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}
		.phone-icon {
			position: relative;
			z-index: 5;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 70px;
			height: 70px;
			margin: 8px;
			background-color: #eb4471;
			img {
				width: 60%;
				transform: rotate(-45deg);
			}
		}
	}
	.sun {
		position: relative;
		font-size: 14px;
		text-decoration: none;
		color: #aab0cc;
		&:after {
			content: attr(data-h);
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			color: #fff;
			width: 0;
			white-space: nowrap;
			transition: width .32s linear 0s;
		}
		&:hover {
			&:after {width: 100%; transition-delay: 0s;}
			span:after {width: 100%; transition-delay: .2s; /*transition-delay: .4s;*/}
		}
		span {
			display: inline-block;
			position: relative;
			&:after {
				content: attr(data-h);
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;
				color: #fbca33;
				width: 0;
				white-space: nowrap;
				transition: width .1s linear;
			}
		}
	}
}



/* media */
@media #{$xlg} {

}

@media #{$lg} {

}
@media #{$md} {
	.footer {
		padding: 55px 15px 40px;
		.contact {padding-bottom: 10px;}
		.phone-block {width: 50%;}
		.social-block {margin-left: 10%;}
	}
}
@media #{$sm} {
	.footer {
		padding: 60px 0 40px;
		.trade-logo {margin: 0 3.5% 40px;}
		.contact {
			flex-direction: column-reverse;
			padding-left: 10%;
		}
		.social-block {
			position: relative;
			display: table;
			margin: 10px 0 0;
			.fb-text {margin-right: 75px;}
			.fb-icon {
				position: absolute;
				right: 0;
				top: 55%;
				transform: rotate(45deg) translate(-50%,-50%);
			}
		}
		.phone-block {
			width: 100%;
			padding-left: 0;
		}
		.phone-img {
			right: 0;
			left: auto;
			transform: rotate(-45deg) translate(45%,0%);
			&:before {
				left: auto;
				right: 78%;
				height: 110%;
				transform: rotate(5deg);
			}
			.phone-icon {
				img {transform: rotate(45deg);}
			}
		}
		.hotline {font-size: 18px;}
		.tel {font-size: 34px;}
		.hotline-info {width: 65%;}
		.sun {
			display: table;
			margin: 30px auto 0;
		}
	}
}
@media #{$xs} {
	.footer {
		.trading-networks {
			width: 100%;
			padding-bottom: 0;
		}
		.contact {padding-left: 4%;}
		.phone-img {
			width: 60px;
			transform: rotate(-45deg) translate(-70%,0%);
			transform-origin: 0 0 0;
			.phone-icon {
				width: 50px;
				height: 50px;
				margin: 5px;
			}
		}
		.hotline {font-size: 16px;}
		.tel {font-size: 28px;}
	}
}
