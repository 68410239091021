
$large: 1000px;
$medium: 768px;
$small: 480px;

/* mixins */

@mixin respond-to($media) {
  @if $media == lessSmall {
    @media (max-width: $small){ @content; }
  }  
  @else if $media == lessMedium {
    @media (max-width: $medium){ @content; }
  }
  @else if $media == lessLarge {
    @media (max-width: $large) { @content; }
  }  
  @else if $media == biggerLarge {
    @media (min-width: $large + 1) { @content; }
  }
}



@mixin clear() {
  content: '';
  display: table;
  clear: both;
}

@mixin fz($fz) {
  font-size: ($fz/20)*1rem;
}

@mixin check($r, $w, $h) {
  &:before {
   content: '';
   position: absolute;
   z-index: 2;
   bottom:30%;
   left: $w/1.2;
   width: $w;
   height: $h;
   border-right: 4px solid #341209;
   border-bottom: 4px solid #341209;
   transform: rotate(45deg);
 }
   &:after {
    content: '';
    display: block;
    width: $r;
    height:$r;
    border: 3px solid #cca466;
    border-radius: 50%;
  }

}

@mixin loader($w, $h) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $w;
  height: $h;
  border: $w/6 solid #f3f3f3;
  border-top: $w/6 solid  #cca466;
  border-bottom: $w/6 solid #cca466;
  border-radius:50%;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* extends */

%def-a {
  color: inherit;
  text-decoration: none;
}

%p-slider {
  bottom: 31px;
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    margin: 0 20px;
    opacity: 1;
    background: #fff;
    border:2px solid #cca466;
    &:hover { background: #4d1b0e;}
  }
  .swiper-pagination-bullet-active {
    background: #4d1b0e;
  }
}

%b-line {
  position: absolute;
  bottom:0px;
  left: -50%;
  z-index: 5;
  width: 200%;
  height: 0px;
  border-bottom: 1px solid #cca466;
}


%label {
    position: absolute;
    top:-35px;
    right: 56px;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    color: #fff;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: #4d1b0e;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .icon {
      font-size: 40px;
      vertical-align: middle;
    }
}
%label-relative {
    position: relative;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    color: #fff;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: #4d1b0e;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .icon {
      font-size: 40px;
      vertical-align: middle;
    }
}


%burger {
  width: 32px;
  height: 30px;
  cursor: pointer;
  //&:active {transform:scale(.9);}
  &:before, &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height:1px;
    background: #341209;
    transition:  .4s;
  }
  &:before {     
    top: 0;   
     transform-origin: left center;
  }
  &:after {      
    bottom: 0;    
    transform-origin: left center;
  }
  span {
    position: absolute;
    top: 50%;
    margin-top: -1px;
    width: 100%;
    height: 1px;
    background: #341209;
    transition: .4s;
    transform-origin: left center;
  }
  &.open:before {
    transform: rotate(45deg);
    top:8.5%;
  }  
  &.open span {
    width: 0%;
    opacity: 0;
  }
  &.open:after {
    transform: rotate(-45deg);
    bottom: 11.5%;
  }
}

%slider-arrow {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  width: 25px;
  height: 25px;
  border-top:3px solid #4d1b0e;
  border-left:3px solid #4d1b0e;
  cursor: pointer;
  transition:opacity .5s;
  &.swiper-button-disabled {opacity:.2;pointer-events:none;}
  &.arr-next {
    right: -35px;
    transform:rotate(135deg);
  }
  &.arr-prev {
    left: -35px;
    transform:rotate(-45deg);
  }
  &:before {
    content:'';
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top:3px solid #cca466;
    border-left:3px solid #cca466;
  }
  &:hover, &:focus {
    &:before {display: block;}
  }
}

%btn {
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  font-size: 20px;
  color: #341209;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background: #cca466;
  transition: background .3s;
  &:hover, &:focus {
    color:#341209;
    background: #cfaf7f;
  }
  &:active {    transform: scale(0.95);}
  @media (max-width:500px) {max-width: 100%;}
}



%bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}



%customScroll {
  overflow: auto;
  @media(min-width:1241px) {
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #d8c8ae;
    }
    &::-webkit-scrollbar-thumb {
      background: #321109;
    }
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}


