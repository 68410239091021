$ff-main: 'Proxima Nova', sans-serif;
$fz: 20;
$fz-main: $fz + px;
$color-main:#666;
$color-content:#253d90;

$bg-main:#fff;
$transitionNav: .5s;
//$contentWidth:1334px;

// custom vars
//$b: 1px solid #cca466;
//$ls:-0.025em;
//$ts:2.121px 2.121px 0px rgba(11, 11, 11, 0.75);
//$hc:#cca466;
//$hover:#cfaf7f;
//$cc:#341209; /* color content */


// media 
$xxs: "(max-width: 320px)";
$xs: "(max-width: 480px)";
$sm: "(max-width: 768px)";
$md: "(max-width: 1100px)";
$lg: "(max-width: 1240px)";
$xlg: "(max-width: 1500px)";



/* media */
@media #{$xlg} {

}

@media #{$lg} {

}
@media #{$md} {

}
@media #{$sm} {

}
@media #{$xs} {

}
