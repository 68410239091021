.section {
	width: 100%;
	height: 100vh;
	@media #{$md} {
		height: auto;
	}
}

.tablet {
	.section {height: auto;}
	.hero-slider-wrap {
		height: 770px;
	}
	.hero-slider {
		&__item {
			.isDesktop {display: none;}
			.isMobile {display: block;}
			.brand-desc {font-size: 24px;}
		}
	}
	.recipes-slider-wrap {
		height: 650px;
	}
	.about {padding: 90px 0 60px;}
}


.hero-slider-wrap {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	.hero-arr {
		width: 106px;
		height: 106px;
		background: #eb4471;
		position: absolute;
		top: 50%;
		cursor: pointer;
		z-index: 50;
		&:before {
			content: '';
			display: block;
			width: 33px;
			height: 33px;
			position: absolute;
			transform: translate(-50%,-50%);
			transition: transform .15s;
		}
	}
	.hero-arr-prev {
		left: 0;
		transform: translate(-50%,-50%) rotate(45deg);
		&:before {
			top: 27%;
			left: 73%;
			border-left: 5px solid #fff;
			border-bottom: 5px solid #fff;
		}
		&:hover:before {transform: translate(-65%,-40%);}
	}
	.hero-arr-next {
		right: 0;
		transform: translate(50%,-50%) rotate(45deg);
		&:before {
			top: 73%;
			left: 27%;
			border-right: 5px solid #fff;
			border-top: 5px solid #fff;
		}
		&:hover:before {transform: translate(-35%,-60%);}
	}
	.swiper-button-disabled {
		cursor: default;
		&:hover:before {transform: translate(-50%,-50%);}
	}
	.hero-swiper-pagination {
		bottom: 40px;
		left: 4.2%;
		.swiper-pagination-bullet {
			width: 7px;
			height: 7px;
			border-radius: 0;
			background: $color-content;
			transform: rotate(45deg);
			margin-right: 20px;
			opacity: 1;
		}
		.swiper-pagination-bullet-active {
			background: #eb4471;
			transform: rotate(45deg) scale(2);
		}
	}
}

			.silpo-logo {
				position: absolute;
				left: 10%;
				bottom: 20%;
				z-index: 5;
				transform: translate(-50%,50%);
				@media #{$lg} {display: none;}
			}

		.white-text-block {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 350px;
			height: 800px;
			padding: 23px 23px 40px;
			position: absolute;
			left: 50%;
			bottom: 0;
			z-index: 20;
			transform: translate(-50%,0) rotate(45deg);
			transform-origin: 50% 100% 0;
			opacity: 1;
			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 200%;
				background: #fff;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
			}
			.logo {
				width: 304px;
				height: 304px;
				position: relative;
				z-index: 5;
				img {
					display: block;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			.brand-name {
				display: flex;
				align-items: center;
				text-align: center;
				align-self: flex-start;
				width: 142px;
				height: 142px;
				margin-top: 23px;
				margin-bottom: 60px;
				color: #fff;
				font-size: 22px;
				font-weight: bold;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				line-height: 1.25em;
				opacity: 1;
				transition: opacity .1s ease-out 1s;
				position: relative;
				z-index: 4;
				&:before {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background: #f36c26;
					position: absolute;
					left: 0;
					top: 0;
					z-index: 0;
				}
				p {
					transform: rotate(-45deg);
					margin-bottom: 0;
					padding-top: 5px;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 2;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
				}
			}
			.brand-desc {
				font-size: 36px;
				font-weight: bold;
				color: $color-content;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				transform: rotate(-45deg);
				opacity: 1;
				transition: opacity .1s ease-out 1s;
				position: relative;
				z-index: 3;
			}
		}

.hero-slider {
	width: 100%;
	height: 100%;
	background: #ccc;
	position: relative;
	&__item {
		height: 100%;
		background-repeat: no-repeat;
		background-position: right center;
		background-size: cover;
		background-color: #fff;
		position: relative;
		.white-corner {
			width: calc(50% - 248px);
			height: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 5;
			&:after {
				content: '';
				display: block;
				width: 0;
				height: 0;
				border-top: 1250px solid transparent;
				border-left: 1250px solid #fff;
				position: absolute;
				bottom: 0;
				right: 0;
				z-index: 1;
			}
		}
		.blue-text-block {
			display: flex;
			align-items: center;
			width: 350px;
			height: 160%;
			color: #fff;
			font-size: 30px;
			font-weight: 800;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			line-height: 1.5em;
			position: absolute;
			left: 50%;
			bottom: 0;
			z-index: 10;
			transform: translate(-50%,0) rotate(-45deg);
			transform-origin: 50% 100% 0;
			&:before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				background: $color-content;
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
			}
			.inner-blue-block {
				transform: rotate(45deg);
				text-align: center;
				margin-bottom: 2em;
			}
		}

		.product {
			width: 27%;
			text-align: center;
			position: absolute;
			left: 35%;
			top: 50%;
			z-index: 15;
			transform: translate(-50%,-50%);
			opacity: 0;
			//transition: opacity .2s ease 1.4s, left .4s ease-out 1.4s;
		}
	}
	.swiper-slide-next, .swiper-slide-prev {
		.product {
			opacity: 0;
		}
	}
	.swiper-slide-active {
		.product {
			//opacity: 1;
			left: 45%;
			animation: product-anim-full 5.1s ease-out 1.4s;
		}
	}
}

.swiper-container-horizontal {
	.hero-slider__item {
		.white-text-block {
			height: 800px;
			opacity: 1;
			.brand-name {opacity: 1;}
			.brand-desc {opacity: 1;}
		}
		.blue-text-block {
			&:before {height: 100%;}
			p {opacity: 1;}
		}
	}
}

@keyframes product-anim-full {
	0% {opacity: 0; left: 35%;}
	4% {opacity: 1; left: 40%;}
	8% {opacity: 1; left: 45%;}
	92% {opacity: 1; left: 45%;}
	100% {opacity: 0; left: 45%;}
}

@keyframes product1 {
	0% {opacity: 0;}
	20% {opacity: 1;}
	30% {opacity: 1;}
	50% {opacity: 0;}
	100% {opacity: 0;}
}

@keyframes product2 {
	0% {opacity: 0;}
	30% {opacity: 0;}
	50% {opacity: 1;}
	60% {opacity: 1;}
	90% {opacity: 1;}
	100% {opacity: 0;}
}

@keyframes text1-appear {
	0% {opacity: 0;}
	15% {opacity: 0;}
	20% {opacity: 1;}
	37% {opacity: 1;}
	42% {opacity: 0;}
	100% {opacity: 0;}
}

@keyframes text2-appear {
	0% {opacity: 0;}
	42% {opacity: 0;}
	47% {opacity: 1;}
	90% {opacity: 1;}
	100% {opacity: 0;}
}


.top-text {
	position: absolute;
	right: 10%;
	top: 10%;
			color: #fff;
			font-size: 30px;
			font-weight: 800;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			line-height: 1.5em;
			text-align: center;
}

				.text1 {
					opacity: 0;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					animation: text1-appear 10s ease-out infinite;
				}
				.text2 {
					margin-left: 1.2em;
					opacity: 0;
					animation: text2-appear 10s ease-out infinite;
				}

/* media */
@media #{$xlg} {
			.white-text-block {
				width: 250px;
				//height: 620px;
				height: 600px;
				padding: 18px 18px 40px;
				.logo {
					width: 214px;
					height: 214px;
					img {width: 214px;}
				}
				.brand-desc {
					//font-size: 30px; /*font-size: 33px;*/
					font-size: 27px;
				}
				.brand-name {
					width: 110px;
					height: 110px;
					font-size: 17px;
					margin-top: 18px;
				}
			}
	.hero-slider {
		&__item {
			.white-corner {width: calc(50% - 178px);}
			//.product {width: 25%;}
			.blue-text-block {
				width: 250px;
				font-size: 22px;
			}

		}
	}
	.swiper-container-horizontal {
		.hero-slider__item {
			.white-text-block {
				//height: 90%;
				height: 620px;
			}
		}
	}
}

@media #{$md} {
	.hero .hero-slider-wrap {
		height: 770px;
	}
			.white-text-block {
				width: 200px;
				height: 440px;
				padding: 15px 15px 40px;
				.logo {
					width: 170px;
					height: 170px;
					img {width: 170px;}
				}
				.brand-desc {font-size: 20px; /*font-size: 24px;*/}
				.brand-name {
					width: 90px;
					height: 90px;
					font-size: 14px;
					margin-top: 15px;
					margin-bottom: 45px;
				}
			}
	.hero-slider {
		&__item {
			.white-corner {width: calc(50% - 141px);}
			.product {
				width: 38%;
			}
			.blue-text-block {
				width: 200px;
			}

		}
		/*.swiper-slide-active {
			.product {
				left: 47%;
			}
		}*/
	}
	.swiper-container-horizontal {
		.hero-slider__item {
			.white-text-block {
				//height: 480px;
				//height: 450px;
				height: 440px;
			}
		}
	}
}

@media #{$sm} {
	.top-text {
		right: 5%;
	}
	.hero .hero-slider-wrap {
		height: 700px;
		.hero-swiper-pagination {
			width: 100%;
			bottom: 25px;
			left: 50%;
			transform: translate(-50%,50%);
			.swiper-pagination-bullet {
				width: 8px;
				height: 8px;
			}
		}
	}
			.white-text-block {
				//bottom: 50px;
				transform: translate(-100%,0) rotate(45deg);
			}
	.hero-slider {
		//height: 100%;
		height: calc(100% - 50px);
		overflow: hidden;
		&__item {
			//.white-corner {width: calc(50% - 160px);}
			//.product {width: 43%;}
			.product {width: 44%;}
			.blue-text-block {
				transform: translate(-100%,0%) rotate(-45deg);
			}

		}
	}
	.swiper-container-horizontal {
		.hero-slider__item {
			.white-text-block {
				//height: 360px;
			}
		}
	}
}

@media (max-width:640px) {
	.top-text {
		font-size: 24px;
		right: 3%;
	}
	.hero .hero-slider-wrap {
		height: 650px;
	}
			.white-text-block {
				width: 160px;
				height: 350px;
				padding: 10px 10px 20px;
				.logo {
					width: 140px;
					height: 140px;
					img {width: 140px;}
				}
				.brand-desc {font-size: 16px;}
				.brand-name {
					width: 80px;
					height: 80px;
					font-size: 12px;
					margin-top: 10px;
					margin-bottom: 40px;
				}
			}
	.hero-slider {
		&__item {
			.white-corner {width: calc(50% - 160px);}
			.product {width: 50%;}
			.blue-text-block {
				width: 150px;
			}

		}
	}
	.swiper-container-horizontal {
		.hero-slider__item {
			.white-text-block {
				height: 350px;
			}
		}
	}
}

@media #{$xs} {
	.top-text {
		font-size: 20px;
	}
	.hero .hero-slider-wrap {
		height: 100vh;
		//height: 610px;
		.hero-arr {
			top: calc(50% - 25px);
			width: 71px;
			height: 71px;
			&:before {
				width: 20px;
				height: 20px;
			}
		}
		.hero-arr-prev {
			&:before {
				border-left-width: 3px;
				border-bottom-width: 3px;
			}
		}
		.hero-arr-next {
			&:before {
				border-right-width: 3px;
				border-top-width: 3px;
			}
		}
	}
			.white-text-block {
				width: 140px;
				height: 280px;
				left: 45%;
				padding: 10px 10px 20px;
				.logo {
					width: 120px;
					height: 120px;
					img {width: 120px;}
				}
				.brand-desc {font-size: 14px;}
				.brand-name {
					width: 64px;
					height: 64px;
					font-size: 11px;
					margin-bottom: 25px;
				}
			}
	.hero-slider {
		&__item {
			.white-corner {width: calc(50% - 140px);}
			.product {width: 60%;}
			.blue-text-block {
				width: 120px;
			}

		}
	}
	.swiper-container-horizontal {
		.hero-slider__item {
			.white-text-block {
				height: 280px;
			}
		}
	}
}
