.about {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	&:before {
		content: '';
		display: block;
		width: 323px;
		height: 647px;
		background-image: url(../img/about-bg.png);
		background-repeat: no-repeat;
		background-position: left center;
		position: absolute;
		left: 0;
		bottom: -235px;
		z-index: 0;
		pointer-events: none;
	}
	&__content {
		width: 29%;
		margin-left: 8.5%;
		position: relative;
		z-index: 10;
		transform: none;
	}
	&__img {
		max-width: 61.6%;
		margin-top: 40px;
		position: relative;
		z-index: 10;
	}
	&__bg {
		width: calc(50% + 248px);
		height: 130%;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			border-bottom: 1250px solid transparent;
			border-right: 1250px solid #b4d154;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			animation: bg-color 20s ease infinite;
		}
	}
}

.section-title {
	color: $color-content;
	line-height: 1;
	font-weight: bold;
	text-transform: uppercase;
	padding: 25px 0;
	@media #{$md} {
		text-align: center;
		padding: 25px 15px;
	}
	.desc {
		letter-spacing: .05em;
		margin-bottom: 0;
	}
	.tit {
		font-size: 70px;
		margin-bottom: 0;
		@media #{$md} {font-size: 58px;}
	}
}

@keyframes bg-color {
	0% {border-right-color: #b4d154;}
	22% {border-right-color: #b4d154;}
	33% {border-right-color: #e3d198;}
	55% {border-right-color: #e3d198;}
	66% {border-right-color: #74b5dd;}
	88% {border-right-color: #74b5dd;}
	100% {border-right-color: #b4d154;}
}


/* media */
@media #{$xlg} {
	.about {
		&__bg {width: calc(50% + 178px);}
	}
}

@media #{$md} {
	.about {
		&__bg {width: calc(50% + 141px);}
		padding: 90px 0 60px;
		flex-direction: column-reverse;
		justify-content: flex-start;
		&__content {
			width: 100%;
			max-width: 600px;
			margin: 20px auto 0;
		}
		&__img {
		}
	}
}

@media #{$sm} {
	.about {
		&:before {display: none;}
		&__text {padding: 0 15px;}
		&__img {
			max-width: 95%;
			padding-right: 10px;
		}
		&__bg {width: 100%;}
	}
}

@media #{$xs} {
	.about {
		padding: 60px 0 40px;
	}
}