html {
  font-size:$fz-main;
  font-family: Sans-Serif;
  box-sizing: border-box;
  font-weight: normal;
  @media #{$xlg} {
    font-size:18px;
  }
  @media #{$lg} {
  }
}


body {
  font-family: $ff-main;
  font-size: $fz-main;
  color: $color-main;
  line-height: 1.2;
  background: $bg-main;
  //&.hidden, &.openNav {overflow: hidden;}
  @media #{$xlg} {
    font-size:18px;
  }
  @media #{$sm} {
    font-size: 16px;
  }
}

//html, body {height: 100%;}

.wrapper {
  //position: relative;
  //min-height: 100vh;
  //height: 100%;
}

/*.container {
	width: $contentWidth;
  max-width: 100%;
	margin: 0 auto;
  box-sizing: content-box;
}*/



h1,h2,h3,h4,h5,h6, strong {font-weight: bold; margin-bottom: 0;}

.bg-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* pages */




/* media */
.isDesktop {
  display: none;
  @media (min-width:1101px) {
    display: block;
  }
}
.isMobile {
  display: none;
  @media #{$md} {
    display: block;
  }
}


@media #{$xlg} {

}

@media #{$lg} {
  * {
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

}
@media #{$md} {

}
@media #{$sm} {

}
@media #{$xs} {

}
