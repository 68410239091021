.recipe {
	min-height: 100vh;
	overflow: hidden;
	background: #f6f6f6;
	padding: 25px 33px 50px;
	position: relative;
	font-size: 16px;
	&__content {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 25px - 50px);
		background: #fff;
		position: relative;
	}
	&__text {
		width: 50%;
		padding: 30px 80px 25px 98px;
		position: relative;
		color: #666;
		z-index: 0;
		&:before {
			content: '';
			display: block;
			width: 215px;
			height: 370px;
			background-image: url(../img/about-bg.png);
			background-repeat: no-repeat;
			background-position: right center;
			position: absolute;
			right: 0;
			top: 50%;
			z-index: -1;
			pointer-events: none;
			transform: translate(0,-50%) rotate(180deg);
		}
		.tit {
			font-size: 14px;
			color: #b4d154;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			padding-top: 5px;
			margin-bottom: 10px;
		}
		.ingredients {
			ul {
				padding-left: 0;
				list-style-position: inside;
				list-style-type: none;
				li {
					padding-left: 10px;
					position: relative;
					&:before {
						content: '';
						display: block;
						width: 4px;
						height: 4px;
						border-radius: 50%;
						background: #666;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
		.cooking {
			ol {
				padding-left: 28px;
				margin: 30px 0;
				list-style-position: inside;
				list-style-type: none;
				counter-reset: li;
				li {
					position: relative;
					margin-bottom: 18px;
					&:before {
						counter-increment: li;
						content: counters(li,".") ". ";
						position: absolute;
						top: 0;
						left: -28px;
						color: #b4d154;
						font-weight: bold;
					}
				}
			}
		}
	}
	&__title {
		width: 50%;
		font-size: 50px;
		color: $color-content;
		letter-spacing: -0.025em;
		line-height: 1;
		padding: 30px 80px 15px 98px;
	}
	&__img {
		width: 50%;
		height: 100%;
		margin-left: 50%;
		position: absolute;
		right: 0;
		top: 0;
		.recipe-photo {
			height: calc(100% - 300px);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
}

.details {
	display: flex;
	justify-content: space-between;
	&__item {
		width: 25%;
		margin-bottom: 20px;
	}
	.icon {width: 40px;}
}

.product-slider-wrap {
	width: 100%;
	height: 300px;
	position: relative;
	.product-arr {
		width: 33px;
		height: 33px;
		position: absolute;
		top: 50%;
		cursor: pointer;
		z-index: 50;
		transition: transform .15s;
	}
	.product-arr-prev {
		left: 20px;
		border-left: 5px solid #fff;
		border-bottom: 5px solid #fff;
		transform: rotate(45deg);
		transition: left .15s;
		&:hover {left: 15px;}
	}
	.product-arr-next {
		right: 20px;
		border-right: 5px solid #fff;
		border-bottom: 5px solid #fff;
		transform: rotate(-45deg);
		transition: right .15s;
		&:hover {right: 15px;}
	}
	.swiper-button-disabled {
		display: none;
	}
}

.product-slider {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.tit {
		color: #333;
		text-transform: uppercase;
		padding-top: 20px;
	}
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 20px;
		text-align: center;
		&:nth-child(odd) {background: #d9e8a9;}
		&:nth-child(even) {background: #cedaa7;}
		img {height: 70%;}
	}
}

.close {
	display: block;
	width: 46px;
	height: 46px;
	background: #eb4471;
	position: fixed;
	top: 11px;
	right: 11px;
	z-index: 1000;
	transform: rotate(45deg);
	&:before, &:after {
		content: '';
		display: block;
		width: 25px;
		height: 3px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}
	&:before {
		transform: translate(-50%,-50%) rotate(90deg);
	}
	&:hover {
		&:after {transform: scale(1.1) translate(-50%,-50%);}
		&:before {transform: scale(1.1) translate(-50%,-50%) rotate(90deg);}
	}
}

.arr {
	display: block;
	width: 106px;
	height: 106px;
	background: #eb4471;
	position: fixed;
	top: 50%;
	cursor: pointer;
	z-index: 1000;
	&:before {
		content: '';
		display: block;
		width: 33px;
		height: 33px;
		position: absolute;
		transform: translate(-50%,-50%);
		transition: transform .15s;
	}
}

.arr-prev {
	left: 0;
	transform: translate(-50%,-50%) rotate(45deg);
	&:before {
		top: 27%;
		left: 73%;
		border-left: 5px solid #fff;
		border-bottom: 5px solid #fff;
	}
	&:hover:before {transform: translate(-65%,-40%);}
}
.arr-next {
	right: 0;
	transform: translate(50%,-50%) rotate(45deg);
	&:before {
		top: 73%;
		left: 27%;
		border-right: 5px solid #fff;
		border-top: 5px solid #fff;
	}
	&:hover:before {transform: translate(-35%,-60%);}
}

@media #{$lg} {
	.recipe {
		&__content {
		}
		&__text {
			width: 100%;
			margin-top: 20px;
			order: 3;
			&:before {display: none;}
		}
		&__title {
			width: 100%;
			text-align: center;
			margin-bottom: 15px;
			order: 1;
		}
		&__img {
			width: 100%;
			height: 900px;
			margin: 0 auto;
			padding: 0 80px 0 98px;
			position: static;
			order: 2;
		}
	}
}

@media #{$md} {
	.recipe {
		&__img {
			padding: 0;
		}
	}
}

@media #{$sm} {
	.recipe {
		padding: 18px 22px 30px;
		&__text, &__title {
			padding-left: 50px;
			padding-right: 50px;
		}
		&__title {font-size: 36px;}
		&__img {
			height: auto;
			.recipe-photo {height: 380px;}
			.product-slider-wrap {height: 220px;}
		}
	}
	.product-slider-wrap {
		.product-arr {
			width: 20px;
			height: 20px;
		}
		.product-arr-prev {
			left: 20px;
			border-left-width: 3px;
			border-bottom-width: 3px;
			&:hover {left: 15px;}
		}
		.product-arr-next {
			right: 20px;
			border-right-width: 3px;
			border-bottom-width: 3px;
			&:hover {right: 15px;}
		}
	}
	.product-slider {
		.tit {font-size: 14px;}
	}
	.arr {
		width: 71px;
		height: 71px;
		&:before {
			width: 20px;
			height: 20px;
		}
	}
	.arr-prev {
		&:before {
			border-left-width: 3px;
			border-bottom-width: 3px;
		}
	}
	.arr-next {
		&:before {
			border-right-width: 3px;
			border-top-width: 3px;
		}
	}
	.close {
		width: 33px;
		height: 33px;
		top: 8px;
		right: 6px;
		&:before, &:after {
			width: 20px;
			height: 2px;
		}
	}
}

@media (max-width:640px) {
	.details {
		flex-wrap: wrap;
		&__item {
			width: 50%;
		}
	}
}

@media #{$xs} {
	.recipe {
		&__text, &__title {
			padding-left: 30px;
			padding-right: 30px;
		}
		&__title {font-size: 30px;}
		&__text {margin-top: 0;}
		&__img {
			.recipe-photo {height: 260px;}
			.product-slider-wrap {height: 180px;}
		}
	}
	.arr {
		width: 55px;
		height: 55px;
	}
	.details {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		&__item {
			width: auto;
			text-align: center;
		}
	}
}

