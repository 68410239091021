.recipes {
	//height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	z-index: 10;
	.section-title {
		//margin-left: 8.3%;
		margin-left: 8.5%;
	}
}

.recipes-slider-wrap {
	width: 100%;
	height: 77.77%;
	overflow: hidden;
	position: relative;
	margin-top: 10px;
	.recipes-arr {
		width: 106px;
		height: 106px;
		background: #eb4471;
		position: absolute;
		top: 50%;
		cursor: pointer;
		z-index: 50;
		&:before {
			content: '';
			display: block;
			width: 33px;
			height: 33px;
			position: absolute;
			transform: translate(-50%,-50%);
			transition: transform .15s;
		}
	}
	.recipes-arr-prev {
		left: 0;
		transform: translate(-50%,-50%) rotate(45deg);
		&:before {
			top: 27%;
			left: 73%;
			border-left: 5px solid #fff;
			border-bottom: 5px solid #fff;
		}
		&:hover:before {transform: translate(-65%,-40%);}
	}
	.recipes-arr-next {
		right: 0;
		transform: translate(50%,-50%) rotate(45deg);
		&:before {
			top: 73%;
			left: 27%;
			border-right: 5px solid #fff;
			border-top: 5px solid #fff;
		}
		&:hover:before {transform: translate(-35%,-60%);}
	}
	.swiper-button-disabled {
		cursor: default;
		&:hover:before {transform: translate(-50%,-50%);}
	}
	.recipes-swiper-pagination {
		width: 100%;
		bottom: 25px;
		//left: 67px;
		left: 50%;
		transform: translate(-50%,50%);
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			border-radius: 0;
			background: $color-content;
			transform: rotate(45deg);
			margin-right: 20px;
			opacity: 1;
		}
		.swiper-pagination-bullet-active {
			background: #eb4471;
			transform: rotate(45deg) scale(2);
		}
	}
}

.recipe-slider {
	width: 100%;
	height: 100%;
	background: #fff;
	position: relative;
	&__item {
		height: 50%;
		width: 33.33%;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		font-weight: bold;
		color: #fff;
		text-align: center;
		text-decoration: none;
		display: flex;
		//align-items: center;
		justify-content: center;
		padding: 0 70px;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 5;
			width: 100%;
			height: 0;
			transition: height .3s ease-out;
		}
		&:nth-child(3n+2) {
			background-color: rgba(224,150,46,.9);
			&:before{background: rgba(224,150,46,.9);}
		}
		&:nth-child(3n) {
			background-color: rgba(138,106,154,.9);
			&:before {background: rgba(138,106,154,.9);}
		}
		&:nth-child(3n+1) {
			background-color: rgba(153,182,57,.9);
			&:before {background: rgba(153,182,57,.9);}
		}
		&:hover:before {height: 100%;}
		&:hover {
			.recipe-slider__text {transform: translateY(50%);}
		}
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 1;
			//background: rgba(37,61,144,.1);
		}
	}
	&__text {
		text-transform: uppercase;
		margin-bottom: 10px;
		position: relative;
		z-index: 10;
		transform: translateY(75%);
		transition: all .3s linear;
	}
}


/* media */

@media #{$xlg} {
	.recipe-slider {
		&__item {padding: 0 40px; /*padding: 0 30px;*/}
	}
}

@media #{$md} {
	.recipes {
		display: block;
		.section-title {margin-left: 0;}
		.recipes-slider-wrap {
			height: 650px;
		}
	}
}

@media #{$sm} {
	.recipes-slider-wrap {
		//height: 650px;
		.recipes-arr {
			top: calc(50% - 25px);
		}
		/*.recipes-arr {
			top: calc(50% - 25px);
			width: 71px;
			height: 71px;
			&:before {
				width: 20px;
				height: 20px;
			}
		}
		.recipes-arr-prev {
			&:before {
				border-left-width: 3px;
				border-bottom-width: 3px;
			}
		}
		.recipes-arr-next {
			&:before {
				border-right-width: 3px;
				border-top-width: 3px;
			}
		}*/
	}
	.recipe-slider {
		height: calc(100% - 50px);
		//&__text {font-size: 16px;}
	}
}

@media #{$xs} {
	.recipes-slider-wrap {
		//height: 650px;
		.recipes-arr {
			width: 71px;
			height: 71px;
			&:before {
				width: 20px;
				height: 20px;
			}
		}
		.recipes-arr-prev {
			&:before {
				border-left-width: 3px;
				border-bottom-width: 3px;
			}
		}
		.recipes-arr-next {
			&:before {
				border-right-width: 3px;
				border-top-width: 3px;
			}
		}
	}
	/*.recipe-slider {
		&__text {font-size: 16px;}
	}*/
}
